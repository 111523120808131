@charset "utf-8";
/* CSS Document */

.carousel {
  padding-top: 30px;
  padding-bottom: 30px;

  .carousel-item {
    a {
      display: inline-block;
      outline: none;
    }

    img {
      max-width: 80%;
      margin: 20px auto;
      display: block;
      outline: none;
    }
  }
}

.carousel-caption {
  position: relative;
  left: auto;
  right: auto;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  h3 {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  a {
    background: $primary;
    padding: 10px 15px;
    font-weight: 600;
    color: $white;
    text-decoration: none;
    border-radius: 5px;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-indicators {
  bottom: 15px;

  li {
    margin-left: 5px;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    transition: width .3s;
    border: none;
  }

  .active {
    width: 40px;
  }
}

.alert {
  padding: 10px 15px;
  margin-bottom: 10px;
}

.form-group {
  label {
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: .25rem;
  }
}


@include media-breakpoint-up(md) {

  .carousel {
    padding-top: 50px;
    padding-bottom: 70px;
  }

  .carousel-indicators {
    bottom: 25px;
  }

  .carousel-caption {
    margin-top: 30%;
    padding-left: 15px;
    padding-right: 15px;

    p {
      font-size: 1rem;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
  }

}


@include media-breakpoint-up(lg) {

  .carousel-caption {
    padding-left: 30px;
    padding-right: 30px;
  }

}
