/* Sitemap Page */
.sitemap {
  list-style-type: none;

  > li {
    border-left: 3px solid $grey-e;
    margin-bottom: 5px;
  }

  li {
    a {
      display: inline-block;
      padding: 2px 7px;
      color: $secondary-alt;

      &:hover {
        text-decoration: none;
      }
    }

    h4 {
      padding: 4px 7px;
      font-size: 14px;
      margin-bottom: 10px;
    }

    ul {
      margin-left: 20px;

      li {
        list-style-type: none;

        a {
          color: $secondary-alt;

          &:before {
            content: "â ";
            padding-right: 5px;
            color: #ccc;
            font-size: 12px;
          }
        }
      }
    }

    &.sitemap-header {
      list-style-type: none;
      color: $grey-9;
      margin-top: 10px;
    }
  }
}

.sitemap-icon {
  font-size: 100px;
  color: $grey-e;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}


@include media-breakpoint-up(sm) {

  .sitemap-icon {
    font-size: 150px;
  }

}


@include media-breakpoint-up(lg) {

  .sitemap-icon {
    font-size: 200px;
  }

}
