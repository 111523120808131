/* ==========================================================================
   Reset
   ========================================================================== */
html {
  -webkit-text-size-adjust: none; /* Prevent font scaling in landscape */
}

body {
  color: #666;
  font: 16px/32px 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6,
ul, ol, li,
form, input, textarea, select {
  margin: 0;
  padding: 0;
}

header, nav, article, footer, address, section {
  display: block;
}

img {
  border: 0;
}

a {
  color: $secondary-alt;
}
