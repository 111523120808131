/* Footer
------------------------------------- */
footer {
  background: $bg-footer;
  padding: 10px 15px;
  font-size: 12px;
  color: $grey-9;

  a {
    color: $grey-9;
    text-decoration: none;

    &:hover {
      color: $grey-d;
    }
  }
}

.footer-left {
  text-align: center;
}

.footer-right {
  text-align: center;
}

.footer-links a {
  text-decoration: none;
}

.copyright span {
  font-weight: bold;
  color: $grey-d;
}


@include media-breakpoint-up(md) {

  .footer-left {
    text-align: left;
  }
  .footer-right {
    text-align: right;
  }

}
