// Variables and Mixins
@import "style/variables";
@import "style/icons";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Vendor Custom
@import "style/bootstrap-custom";

// Custom CSS

// Base
@import "style/base/reset";
@import "style/base/general";

// Layout
@import "style/layout/header";
@import "style/layout/sidebar";
@import "style/layout/footer";

// Pages
@import "style/pages/home";
@import "style/pages/product";
@import "style/pages/client";
@import "style/pages/sitemap";
@import "style/pages/contact";

