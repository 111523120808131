@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?plcdp0') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?plcdp0') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?plcdp0##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right-solid {
  &:before {
    content: $icon-arrow-right-solid; 
  }
}
.icon-envelope-solid {
  &:before {
    content: $icon-envelope-solid; 
  }
}
.icon-file-pdf-solid {
  &:before {
    content: $icon-file-pdf-solid; 
  }
}
.icon-home-solid {
  &:before {
    content: $icon-home-solid; 
  }
}
.icon-phone-alt-solid {
  &:before {
    content: $icon-phone-alt-solid; 
  }
}
.icon-sitemap-solid {
  &:before {
    content: $icon-sitemap-solid; 
  }
}
.icon-user-solid {
  &:before {
    content: $icon-user-solid; 
  }
}
.icon-youtube-brands {
  &:before {
    content: $icon-youtube-brands; 
  }
}

