/* Home Page */
.news-and-events h3 {
  margin-bottom: 10px;
}

.news-date {
  font-weight: bold;
}

.news-date + p {
  margin-top: 5px;
}

.testimonials h3 {
  margin-bottom: 10px;
}

a.brochure-download {
  background: $primary;
  padding: 10px 15px 10px 15px;
  font-size: 16px;
  color: $white;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  line-height: 32px;

  &:hover {
    text-decoration: none;
  }

  img {
    vertical-align: middle;
    margin-right: 10px;
  }
}
