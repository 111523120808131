/* Clients Page */
ul.clients {
  list-style-type: none;
  margin-bottom: 30px;

  li {
    border-bottom: 1px solid $grey-e;
    padding: 3px 5px;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      color: $secondary-alt;
      background: $bg-light;
      text-indent: 3px;
    }
  }
}
