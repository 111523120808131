.product-list {
  list-style-type: none;
  margin-top: 5px;
  padding-left: 0;

  li {
    position: relative;
    padding: 10px;
    border-top: 1px solid $grey-e;
    transition: background-color .3s ease, color .3s ease;

    &:hover {
      background: $bg-light;

      a {
        color: $primary;
      }
    }

    &.active {
      background-color: rgba($secondary-alt, .15);
    }

    .heading04 {
      font-size: 1rem;
    }

    a {
      color: $secondary-alt;
      transition: color 0.3s ease;

      &:hover {
        text-decoration: none;
      }
    }

    p {
      font-size: .875rem;
      margin-bottom: 0;
      margin-top: 5px;
      line-height: 21px;
    }
  }

  .product-category {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: .875rem;
    color: $white;
    text-transform: uppercase;
    background-color: $primary;
    border-top: none;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}


@include media-breakpoint-up(lg) {

  #product-list.collapse {
    display: block !important;
  }

}
