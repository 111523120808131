/* Product Pages */
.product-main {
  margin-top: 15px;

  ol {
    margin-left: 40px;
  }

  .heading03 {
    margin-top: 15px;
  }

  .heading04 {
    margin-bottom: 10px;
  }
}

.product-image img {
  display: block;
  margin: 0 auto;
}

.specifications {
  list-style-type: none;

  li {
    padding: 5px;
    transition: background-color .3s, color .3s;

    &:nth-child(even) {
      background: $bg-light;
    }

    &:hover {
      color: $secondary-alt;
      background: rgba($secondary-alt, .1);
    }
  }
}

.spec-label {
  padding: 0 5px;
  font-weight: bold;
}

.spec-detail {
  padding: 0 5px;
}

.video-wrap {
  border: 5px solid $primary;
}


@include media-breakpoint-up(md) {

  .specifications {
    list-style-type: none;

    li {
      display: flex;
    }
  }

  .spec-label {
    flex-basis: 30%;
    flex-shrink: 0;
    max-width: 30%;
  }

  .spec-detail {
    flex-basis: 70%;
    flex-grow: 1;
  }

}
