/* ==========================================================================
   General
   ========================================================================== */

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.center {
  text-align: center !important;
}

.justify {
  text-align: justify !important;
}

.nofloat {
  float: none !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.clear {
  clear: both;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.fixed {
  position: fixed !important;
}

.shadow {
  border-color: rgba($black, 0);
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
  box-shadow: 0 2px 2px rgba($black, 0.3);
}

/* Margin - Padding */
.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mb30 {
  margin-bottom: 30px;
}

.pt10 {
  padding-top: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pb30 {
  padding-bottom: 30px;
}

/* Headings & Texts */
.page-heading { /*color: #2099d5;*/
  padding: 15px 0;
  margin: 0;
  color: $primary;
}

.heading01 {
  font-size: 24px;
}

.heading02 {
  font-size: 22px;
}

.heading03 {
  font-size: 20px;
}

.heading04 {
  font-size: 18px;
}

.heading05 {
  font-size: 16px;
}

.blue-text {
  color: $secondary-alt;
}

.highlight {
  color: $orange;
  font-weight: normal;
  transition: all 0.5s ease;
  padding: 0 2px;
  border-radius: 3px;
  display: inline-block;
  cursor: default;

  &:hover {
    background-color: $secondary;
    font-weight: normal;
    color: $white;
    text-decoration: none;
  }
}

a.highlight {
  color: $secondary-alt;
  background-color: rgba($secondary-alt, .1);
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

#wrapper {
  width: 100%;
}

/* Lists */
.list01 {
  margin-left: 10px;
  margin-bottom: 20px;
  list-style-type: none;

  li {
    border-left: 3px solid $grey-d;
    padding: 1px 7px;
    transition: all 0.5s ease;
    cursor: default;
    border-radius: 0 5px 5px 0;
    margin-bottom: 5px;

    &:hover {
      background: $bg-light;
      padding: 1px 12px;
      color: $secondary-alt;
    }
  }
}


@include media-breakpoint-up(md) {

  .list01 {
    margin-left: 20px;
  }

}


@include media-breakpoint-up(lg) {

  .page-heading {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .list01 {
    margin-left: 40px;
  }

}
