/* Contact Us Page */
#map-wrapper {
  background: $white;
  border: 1px solid $grey-d;
  box-shadow: 0 0 5px $grey-c;
  margin: 0 0 25px;
  padding: 5px;
}

#map {
  height: 340px;
  margin: 0 auto;
  width: auto;
}

#map img,
.google-maps img {
  max-width: none;
}

.txt-map {
  font-size: 20px;
  color: $secondary-alt;
  margin: 0 0 10px;
  text-decoration: none;
}

ul.contact {
  list-style-type: none;

  li {
    margin: 0 0 10px;

    div:first-child {
      padding-left: 0;
      padding-right: 10px;
    }

    [class^="icon-"],
    [class*="icon-"] {
      font-size: 16px;
      line-height: 30px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background: $secondary-alt;
      text-align: center;
      color: $white;
      display: block;
    }
  }
}


@include media-breakpoint-up(md) {

  ul.contact li div:first-child {
    padding-left: 10px;
    padding-right: 15px;
  }

}
