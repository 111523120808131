/* Navigation
------------------------------------- */

.navbar-toggler {
  &:focus {
    outline: none;
  }
}

.navbar-nav {
  margin-top: 32px;

  .nav-item {
    .nav-link {
      font-size: .875rem;
      font-weight: 600;
      text-transform: uppercase;
      padding: 10px 15px;
      transition: background-color .3s, color .3s;
    }

    &.active {
      > .nav-link {
        background-color: $white;
        color: $primary;
      }
    }
  }
}

.dropdown-menu {
  padding: 8px;
  box-shadow: $box-shadow;
}

.dropdown-header {
  font-weight: 600;
  color: $secondary;
  text-transform: uppercase;
}

.dropdown-item {
  font-size: .875rem;
  line-height: 1.5;

  &:hover {
    background-color: rgba($secondary-alt, .15);
  }
}

.navbar-brand {
  height: auto;
  position: absolute;
  top: -8px;
  left: 0;
  padding: 0;
  box-shadow: $box-shadow;

  img {
    max-height: 75px;
    width: auto;
    max-width: none;
  }
}


/* Header
------------------------------------- */
header {
  background-color: $secondary;
  background-image: linear-gradient(rgba($white,.05) 2px, transparent 2px),
                    linear-gradient(90deg, rgba($white,.05) 2px, transparent 2px),
                    linear-gradient(rgba($white,.05) 1px, transparent 1px),
                    linear-gradient(90deg, rgba($white,.05) 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  min-height: 300px;
  padding-top: 56px;
}


@include media-breakpoint-up(md) {

  // Navigation
  .navbar-nav .nav-item .nav-link {
    padding-left: 10px;
    padding-right: 10px;
  }

  .navbar-brand {
    max-width: 154px;
    padding: 0;

    img {
      height: auto;
      max-height: none;
      max-width: 154px;
    }
  }

}


@include media-breakpoint-up(lg) {

  // Navigation
  .navbar-nav {
    .nav-item {
      .nav-link {
        padding: 15px;
        transition: padding-top .5s, padding-bottom .5s;
      }
    }
  }

  .navbar-nav {
    margin-top: 0;
  }

  .navbar-brand {
    max-width: 250px;
    top: 0;
    transition: max-width .5s;

    img {
      max-width: 250px;
      transition: max-width .5s;
    }
  }

  .navbar-shrink {
    .navbar-brand img {
      max-width: 190px;
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }

    & + header {
      padding-top: 52px;
    }
  }

  .dropdown-menu {
    padding-left: 4px;
    padding-right: 4px;
  }

  /* Header
  ------------------------------------- */
  header {
    padding-top: 62px;
  }

}
